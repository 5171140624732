import { NgModule } from '@angular/core';
import {
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    DialogDescriptionComponent,
    HashtagChipComponent,
    InputGenericSelectComponent,
    InputSearchComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    SeparatorComponent,
} from '@interacta-shared/ui';
import { SharedV2Module } from '@modules/shared-v2/shared-v2.module';
import { AttachmentsHashtagReclassificationComponent } from './components/attachments-hashtag-reclassification/attachments-hashtag-reclassification.component';
import { AttachmentsShareDialogComponent } from './components/attachments-share-dialog/attachments-share-dialog.component';
import { HashtagFilterComponent } from './components/hashtag-filter/hashtag-filter.component';

const components = [
    AttachmentsShareDialogComponent,
    AttachmentsHashtagReclassificationComponent,
    HashtagFilterComponent,
];

const standaloneComponents = [
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    DialogDescriptionComponent,
    HashtagChipComponent,
    InputGenericSelectComponent,
    InputSearchComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    SeparatorComponent,
];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [SharedV2Module, ...standaloneComponents],
})
export class AttachmentsShareModule {}

<interacta-input-generic-select
    [chipSize]="'small'"
    [control]="hashtagControl"
    [enableVirtualScroll]="true"
    [isReadonly]="isReadonly"
    [items]="hashtags$ | async"
    [maxSelectableItems]="1"
    [noResultLabel]="'UI.NO_RESULT' | translate"
    [searchItemTemplate]="searchItemTemplate"
    [selectedItemTemplate]="selectedItemTemplate"
    (search)="search($event)"
/>
<ng-template #searchItemTemplate let-item="item">
    <p
        class="typo-xs my-8 line-clamp-1 break-all"
        [matTooltip]="item.name"
        [matTooltipClass]="'break-all'"
        [matTooltipPosition]="'left'"
    >
        #{{ item.name }}
    </p>
</ng-template>
<ng-template #selectedItemTemplate let-item="item">
    <p
        class="typo-xs line-clamp-1 break-all"
        [matTooltip]="item.name"
        [matTooltipClass]="'break-all'"
    >
        #{{ item.name }}
    </p>
</ng-template>

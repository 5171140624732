import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { unique } from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { buildReference } from '@modules/post/models/attachment/attachment.utils';
import { PostFormActions } from '@modules/post/store/form';
import { Store } from '@ngrx/store';

@Component({
    selector: 'interacta-attachments-share-dialog',
    templateUrl: './attachments-share-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsShareDialogComponent {
    @Input() isOpen = false;
    @Input({ required: true }) communities!: ICommunity[];
    @Input({ required: true }) attachments!: IAttachment[];
    @Output() closing = new EventEmitter();

    constructor(
        private store: Store,
        private router: Router,
    ) {}

    content: 'community-selection' | 'hashtag-reclassification' =
        'community-selection';

    searchControl = new FormControl<string>('');
    selectedCommunity?: ICommunity;

    hashtags: IHashTag[] = [];

    selectCommunity(selectedCommunity?: ICommunity) {
        this.selectedCommunity = selectedCommunity;
    }

    createPost(): void {
        this.hashtags = this.attachments
            .map((attachment) => attachment.hashtags || [])
            .reduce((acc, val) => acc.concat(val), []);

        if (
            this.selectedCommunity &&
            this.selectedCommunity.metadata?.hashTagEnabled &&
            this.selectedCommunity.metadata?.activeHashtags.length > 0 &&
            this.hashtags.length > 0
        ) {
            if (
                this.attachments.some(
                    (attachment) =>
                        attachment?.communityId === this.selectedCommunity?.id,
                )
            ) {
                this.goToCreatePostPage(this.attachments, true);
            } else {
                this.content = 'hashtag-reclassification';
            }
        } else {
            this.goToCreatePostPage(this.attachments, false);
        }
    }

    createPostWithUpdatedHashtag(hashtagsUpdated: Record<number, IHashTag>) {
        const attachmentsWithUpdatedHashtag = this.attachments.map(
            (attachment) => ({
                ...attachment,
                hashtags: unique(
                    attachment.hashtags
                        ?.map((hashtag) => hashtagsUpdated[hashtag.id])
                        .filter((h) => h != null) ?? [],
                    (val) => val.id,
                ),
            }),
        );

        this.goToCreatePostPage(attachmentsWithUpdatedHashtag, true);
    }

    private goToCreatePostPage(
        attachments: IAttachment[],
        hashTagsEnabled?: boolean,
    ) {
        if (this.selectedCommunity) {
            this.store.dispatch(
                PostFormActions.setSharedAttachments({
                    sharedAttachments: attachments.map((a) =>
                        buildReference(a, hashTagsEnabled),
                    ),
                }),
            );
            this.router.navigate([
                '/post',
                'create',
                this.selectedCommunity.id,
            ]);
        }
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ICommunity } from '@modules/communities/models/communities.model';
import {
    IHashTag,
    IHashtagWithOccurrences,
} from '@modules/communities/models/hashtag/hashtag.model';

type RadioOption = 'ignore' | 'replace';
interface IHashtagRadio {
    hashtag: IHashtagWithOccurrences;
    radioOption: RadioOption;
    replacement: IHashTag | null;
    replacementHint: IHashTag | null;
}

@Component({
    selector: 'attachments-hashtag-reclassification',
    templateUrl: './attachments-hashtag-reclassification.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsHashtagReclassificationComponent implements OnInit {
    @Input({ required: true }) community!: ICommunity;
    @Input({ required: true }) hashtags!: IHashTag[];
    @Output() back = new EventEmitter<void>();
    @Output() applyChanges = new EventEmitter<Record<number, IHashTag>>();

    hashtagsWithOccurrences: IHashtagWithOccurrences[] = [];
    hashtagsRadioList: IHashtagRadio[] = [];

    radioOptions: { label: string; value: RadioOption }[] = [
        {
            label: 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.HASHTAG.RADIO_OPTIONS.LABEL_IGNORE',
            value: 'ignore',
        },
        {
            label: 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.HASHTAG.RADIO_OPTIONS.LABEL_REPLACE',
            value: 'replace',
        },
    ];

    ngOnInit() {
        const filteredHashtags = this.hashtags
            .filter((h: IHashTag) => h.communityId !== this.community.id)
            .sort((x, y) => x.id - y.id);

        const occurrencesMap = filteredHashtags.reduce(
            (acc: Map<number, IHashtagWithOccurrences>, curr: IHashTag) => {
                const existing = acc.get(curr.id);
                existing
                    ? existing.occurrences++
                    : acc.set(curr.id, { ...curr, occurrences: 1 });
                return acc;
            },
            new Map<number, IHashtagWithOccurrences>(),
        );

        this.hashtagsWithOccurrences = Array.from(occurrencesMap.values());

        this.hashtagsRadioList = this.hashtagsWithOccurrences.map(
            (hashtag) => ({
                hashtag,
                radioOption: 'ignore',
                replacement: null,
                replacementHint:
                    this.community.metadata?.activeHashtags.find(
                        (destHashtag) => destHashtag.name === hashtag.name,
                    ) ?? null,
            }),
        );
    }

    applyHashtagsChanges() {
        const updatedHashtags: Record<number, IHashTag> = {};

        this.hashtagsRadioList.forEach((hashtagRadio) => {
            if (
                hashtagRadio.radioOption === 'replace' &&
                hashtagRadio.replacement
            )
                updatedHashtags[hashtagRadio.hashtag.id] =
                    hashtagRadio.replacement;
        });

        this.applyChanges.emit(updatedHashtags);
    }

    selectHint(hashtagRadio: IHashtagRadio) {
        hashtagRadio.replacement = hashtagRadio.replacementHint;
        hashtagRadio.radioOption = 'replace';
    }
}
